import { Call_Emit_Response, Caller } from "@/utils/call-service.types";
import { ToastDataType, ToastType } from "@/utils/common.types";
import { Dispatch, SetStateAction } from "react";
import { Socket } from "socket.io-client";
import { captialize } from "@/utils/helper";
import { SOCKET_ERROR_TYPES } from "@/modules/Astro/Strings";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";

//! Start Call Function ---->>
const startCall = async (
  zegoClient: ZegoExpressEngine,
  user: Caller,
  onSuccess: () => void,
  setToastData: Dispatch<SetStateAction<ToastDataType>> | undefined,
  socket: Socket | null | undefined,
  setPrivateCallData: (data: object) => void
) => {
  try {
    const result = await zegoClient.checkSystemRequirements();
    console.log(result);
    //! call invite Event Emit ------
    socket?.emit(
      "fe_private_call_send_invite",
      { userId: user?.userId },
      (response: Call_Emit_Response) => {
        console.log(response);
        if (response?.message === "success") {
          onSuccess();
        } else if (response?.error) {
          if (response.error.type === SOCKET_ERROR_TYPES.INSUFFICIENT_BALANCE) {
            setPrivateCallData({ insufficientBalancePopup: true });
            return;
          }
          setToastData &&
            setToastData({
              message: captialize(response.error.message),
              type: ToastType.ERROR,
              visible: true,
            });
        }
      }
    );
  } catch (error) {
    console.log("Error during start call:", error);
  }
};

//! End Call Function ---->>
const endCall = async (
  zegoClient: ZegoExpressEngine,
  socket: Socket | null | undefined
) => {
  try {
    // Stop local streams and leave room
    // zegoClient?.destroyStream()
    zegoClient.logoutRoom();

    //! call end Event Emit ------
    socket?.emit("fe_private_call_end", {}, (response: object) => {
      console.log("Call end response:", response);
    });
  } catch (error) {
    console.log("Error during end call:", error);
  }
};

//! Mute Remote Audio Function ---->>
const muteRemoteAudio = async (
  zegoClient: ZegoExpressEngine,
  isMuted: boolean,
  socket: Socket | null | undefined
) => {
  try {
    // Mute/unmute microphone
    zegoClient?.muteMicrophone(isMuted);

    //! Update server about mute status
    socket?.emit(
      "fe_private_call_mute_myself",
      { isMuted },
      (response: object) => {
        console.log("Mute audio response:", response);
      }
    );
  } catch (error) {
    console.log("Error during mute remote audio:", error);
  }
};

export { startCall, endCall, muteRemoteAudio };
